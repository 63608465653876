<template>
  <div class="container">
    <div class="bg">
      <header>
        <!-- <van-icon
          name="arrow-left"
          size="20"
          color="#fff"
          @click="$router.go(-1)"
        />
        <img
          src="@/assets/new/history.png"
          alt=""
          @click="$router.push({ name: 'LockmingHistory' })"
        /> -->
      </header>
      <div class="tips">
        <span>{{ $t('lockming').wkb }}</span>
      </div>
      <p>{{ $t('lockming').wkgjd }}</p>
      <p>{{ $t('lockming').qsljl }}</p>
    </div>
    <div class="content">
      <van-list
        class="list-box"
        ref="list"
        v-model="loading"
        :finished="finished"
        :loading-text="$t('public').loadingText"
        :finished-text="$t('public').noMoreData"
        @load="getList"
      >
        <van-row
          type="flex"
          justify="space-between"
          v-for="item in list"
          :key="item.Id"
        >
          <van-col class="item">
            <div class="title">
              {{ showTitle(item) }}
            </div>
            <div class="num">
              <van-field v-model="item.Num" type="number" />
              <span>{{ item.TransferType }}</span>
            </div>
            <h4>{{ $t('lockming').yjzsr }}</h4>
            <div class="income">
              <span>{{ getAllAmount(item) }}</span>
              <span>{{ item.TransferType }}</span>
            </div>
            <div class="ratio">
              <div class="left">
                <p>{{ item.Rate }}%[{{ item.TransferType }}]</p>
                <div>{{ $t('lockming').ck }}[{{ $t('lockming').rlx }}]</div>
              </div>
              <div class="right">
                <p>
                  <span>{{ item.DayNum }}</span> {{ $t('lockming').day }}
                </p>
                <div>{{ $t('lockming').scqx }}</div>
              </div>
            </div>
            <div class="btn" @click="handleClick(item)">
              {{ $t('lockming').buy }}
            </div>
          </van-col>
        </van-row>
      </van-list>
    </div>
    <div class="overlay" v-if="show">
      <div class="box">
        <div class="title">
          {{ info.EnglishName }}
        </div>
        <div class="line">
          <span> {{ $t('lockming').rlx }}</span>
          <span>{{ info.Rate }} %</span>
        </div>
        <div class="line">
          <span> {{ $t('lockming').zysl }}</span>
          <span>{{ info.Num }} {{ info.TransferType }}</span>
        </div>
        <div class="line">
          <span>{{ $t('lockming').yjzsr }}</span>
          <span>{{ getAllAmount(info) }} {{ info.TransferType }}</span>
        </div>
        <div class="line">
          <span>{{ $t('lockming').qbye }}</span>
          <span>{{ getBalance(info.TransferType) }} </span>
        </div>
        <div class="btns">
          <div class="btn" @click="show = false">{{ $t('public').cancel }}</div>
          <div class="btn" @click="handleSub">{{ $t('lockming').buy }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import productApi from '@/api/product'
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      show: false,
      Num: null,
      list: [],
      form: {
        page: 1,
        size: 10
      },
      info: '',
      loading: false,
      finished: false
    }
  },
  mounted() {
    this.getUserInfo()
  },
  computed: {
    ...mapGetters(['lang'])
  },
  methods: {
    showTitle(item) {
      if (this.lang === 'zh') {
        return item.ChineseName
      } else {
        return item.EnglishName
      }
    },
    getBalance(type) {
      return this.userInfo.LegalAmountInfo[type]
    },
    async getUserInfo() {
      this.userInfo = await userApi.userInfo()
    },
    handleClick(item) {
      if (!item.Num || item.Num < item.BuyLow) {
        this.$toast(
          `${this.$t('lockming').tips} ${item.BuyLow} ${item.TransferType}`
        )
        return
      }
      this.info = item
      this.show = true
    },
    getAllAmount(item) {
      if (item.Num) {
        const num = String((item.Rate * item.Num * item.DayNum) / 100)
        if (num.indexOf('.') !== -1) {
          const arr = num.split('.')
          if (arr[1] && arr[1].length > 6) {
            return `${arr[0]}.${arr[1].substr(0, 6)}`
          }
        }
        return num
      } else {
        return '0'
      }
    },
    async handleSub() {
      const BuyNum = Number(this.info.Num)
      if (!BuyNum) {
        this.$toast({
          position: 'bottom',
          message: this.$t('newBe').tips
        })
        return
      }
      const form = {
        ProductId: this.info.Id,
        BuyNum
      }
      await productApi.createMiningOrder(form)
      this.$toast(this.$t('moneyAddr').success)
      setTimeout(() => {
        this.$router.push({ name: 'LockmingHistory' })
      }, 1000)
    },
    async getList() {
      const res = await productApi.getMiningProductist(this.form)
      let data = res.Data
      data.forEach((item) => {
        item.value = null
      })
      let length = data.length
      if (length != 0) {
        this.list.push.apply(this.list, data)
        this.form.page++
      }
      if (length !== this.form.size) {
        this.finished = true
      }
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  .bg {
    width: 100vw;
    height: 400px;
    background: url('~@/assets/pc/pledgebanner.8eba3c5e.png') 0 0 no-repeat;
    background-size: 100% 100%;
    .tips {
      text-align: center;
      margin-bottom: 30px;
      span {
        background: #ffd12d;
        color: #000;
        font-weight: 700;
        padding: 5px 15px;
        border-radius: 15px;
        font-size: 14px;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          bottom: -8px;
          left: 18px;
          border-width: 0 10px 10px;
          border-style: solid;
          border-color: transparent transparent transparent #ffd12d;
        }
      }
    }
    p {
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      margin: 20px auto;
    }
  }
  header {
    /* display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px; */
    height: 44px;
    /* img {
      width: 25px;
      height: 25px;
    } */
  }
  .content {
    width: 40%;
    margin: 0 auto;
  }
  .list-box {
    // height: calc(85vh - 210px);
    overflow: scroll;
  }
  .item {
    width: 100%;
    margin: 20px 18px;
    border-radius: 15px;
    background-color: #fff;
    padding: 20px 15px;
    color: #000;
    .title {
      font-size: 18px;
      font-weight: bold;
      margin-top: 10px;
    }
    .num {
      margin: 10px 0;
      background: rgb(245, 245, 245);
      display: flex;
      align-items: center;
      padding-right: 10px;
      .van-field {
        padding: 5px 16px;
        background: transparent;
      }
    }
    h4 {
      margin: 10px 0;
      font-weight: 400;
    }
    .income {
      display: flex;
      justify-content: space-between;
      span:nth-of-type(1) {
        font-weight: bold;
      }
    }
    .ratio {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        padding: 25px 0 15px;
        margin-bottom: 15px;
        font-size: 15px;
        color: #999;
        p {
          margin-bottom: 5px;
          letter-spacing: 3px;
          font-size: 25px;
          font-weight: 700;
          color: #00b796;
        }
      }
      .right {
        div {
          line-height: 1.5;
          color: #999;
        }
      }
    }
    .btn {
      width: 100%;
      padding: 12px 40px;
      text-align: center;
      font-size: 14px;
      color: #fff;
      background-color: #2c63e4;
      border-radius: 15px;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    .box {
      width: 50%;
      background: #fff;
      border-radius: 4px;
      .title {
        font-size: 20px;
        color: #000;
        text-align: center;
        line-height: 45px;
        border-bottom: 1px solid #ddd;
      }
      .line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        font-size: 14px;
        height: 57px;
        color: #999;
        span:nth-of-type(2) {
          font-weight: bold;
          color: #000;
        }
      }
      .btns {
        padding: 20px 10px 10px;
        display: flex;
        justify-content: space-between;
        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 151px;
          height: 50px;
          background-color: #eee;
          color: #999;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 3px;
          &:nth-of-type(2) {
            border: 0;
            background-color: #0066ed;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
